import React, { useMemo } from "react";
import MaterialTable from "./MaterialTable";
import NavBar from "../../common/navbar/NavBar";
import { RenderStatusColor } from "./../../helpers/ClassHelper";
import PawTrackerDashboardList from "../pawtracker/dashboard/PawTrackerDashboardList";

export default function AeternalPlanDashboard({ data }) {
  const { customer, aeternalPlan, pet, aeternalPlanSched } = data.data;

  const columns = useMemo(
    () => [
      {
        accessorKey: "requestedPaymentDate", //access nested data with dot notation
        header: "Due Date",
        size: 150,
      },
      {
        accessorKey: "requestedPaymentAmount", //normal accessorKey
        header: "Amount to Pay",
        size: 200,
      },
      {
        accessorKey: "paymentStatus", //normal accessorKey
        header: "Payment Status",
        size: 200,
        Cell: ({ cell }) => (
          <div className={`rowTable ${RenderStatusColor(cell.getValue())}`}>
            {cell.getValue()}
          </div>
        ),
      },
      {
        accessorKey: "id",
        header: "Invoice #",
        size: 150,
      },
      {
        accessorKey: "paymentMethod",
        header: "Payment Method",
        size: 150,
      },
      {
        accessorKey: "paymentReference",
        header: "Payment Reference",
        size: 150,
      },
      {
        accessorKey: "createdDateTime",
        header: "Payment Date",
        size: 250,
      },
    ],
    []
  );
  const RenderAmountPaidValue = (amount) =>  {
    let planStatus = aeternalPlan.status.toLowerCase();
    if(planStatus == "claimed") return aeternalPlan.netamount;
    
    return amount;
  }
  const RenderRemainingAmountValue = (amount) =>  {
    let planStatus = aeternalPlan.status.toLowerCase();
    if(planStatus == "claimed") return "0.00";
    
    return amount;
  }

  return (
    <>
      <NavBar isFromPawTracker={true} />
      <div className="ptDashboard">
        <div className="ptdashboard-wrapper">
          <div className="pt-dashboard-details-wrapper">
            <div className="ptdashboard-customer-wrapper">
              <div className="ptdashboard-customer-header-wrapper">
                <div className="ptdashboard-customer-header-title">
                  {customer.firstName} {customer.lastname}
                </div>
              </div>
              <PawTrackerDashboardList
                first={true}
                label={"Mobile"}
                info={customer.mobilenumber}
                img={"smartphone"}
              />
              <PawTrackerDashboardList
                label={"Email"}
                info={customer.email}
                img={"email"}
                last={true}
              />
              <div className="ptdashboard-customer-footer-wrapper">
                <div>
                  <div className="ptdashboard-customer-footer-title">
                    {customer.petCount}
                  </div>
                  <div className="ptdashboard-customer-footer-subtitle">
                    Pet(s)
                  </div>
                </div>
              </div>
            </div>
            {/* package */}
            <div className="ptdashboard-customer-wrapper">
              <div className="ptdashboard-customer-header-wrapper">
                <div className="ptdashboard-customer-header-title">
                  <span className="ptdashboard-customer-header-label-title1">
                    Plan Details
                  </span>
                </div>
                <div
                  className={`ptdashboard-customer-header-subtitle ${RenderStatusColor(
                    aeternalPlan.status
                  )}`}
                >
                  {aeternalPlan.status}
                </div>
              </div>
              <PawTrackerDashboardList
                first={true}
                label={"Package"}
                info={aeternalPlan.longDescription}
              />
              <PawTrackerDashboardList
                label={"Plan Based-Weight"}
                info={aeternalPlan.weight}
              />
              <PawTrackerDashboardList
                label={"Payment Frequency"}
                info={aeternalPlan.paymentFrequency}
              />
              <PawTrackerDashboardList
                label={"Number of Payments"}
                info={aeternalPlan.numberOfPayments}
              />
              <PawTrackerDashboardList
                label={"Created Date"}
                info={aeternalPlan.createdDateTime}
                last={true}
              />
            </div>
            {/* pets */}
            <div className="ptdashboard-customer-wrapper">
              <div className="ptdashboard-customer-header-wrapper">
                <div className="ptdashboard-customer-header-title">
                  <span className="ptdashboard-customer-header-label-title1">
                    {pet.name}
                  </span>
                  <span className="ptdashboard-customer-header-label-title2">
                    {pet.breed}
                  </span>
                </div>
              </div>

              <PawTrackerDashboardList
                first={true}
                label={"Type"}
                info={pet.type}
                img={"type"}
              />
              <PawTrackerDashboardList
                label={"Color"}
                info={pet.color}
                img={"color"}
              />
              <PawTrackerDashboardList
                label={"Weight"}
                info={pet.weight}
                img={"weight"}
              />
              <PawTrackerDashboardList
                label={"Gender"}
                info={pet.gender}
                img={"gender"}
              />
              <PawTrackerDashboardList
                label={"Birthdate"}
                info={pet.birthdate}
                img={"birthday"}
                last={true}
              />
            </div>
          </div>

          <div className="ptdashboard-timeline-wrapper">
            <div className="ptdashboard-timeline-wrapper-container">
              {/* billing table */}
              <div className="ptdashboard-customer-wrapper">
                <div className="ptdashboard-customer-header-wrapper">
                  <div className="ptdashboard-customer-header-title">
                    <span className="ptdashboard-customer-header-label-title1">
                      Billing
                    </span>
                  </div>
                </div>

                <PawTrackerDashboardList
                  label={"Plan Amount"}
                  info={aeternalPlan.netamount}
                  first={true}
                />
                <PawTrackerDashboardList
                  label={"Amount Paid"}
                  info={RenderAmountPaidValue(aeternalPlan.amountPaid)}
                />
                <PawTrackerDashboardList
                  label={"Remaining Amount"}
                  info={RenderRemainingAmountValue(aeternalPlan.remainingAmount)}
                  last={true}
                  renderStatus={true}
                />
              </div>

              {/* payment table */}
              <div className="ptdashboard-customer-wrapper payment-history">
                <div className="ptdashboard-customer-header-wrapper">
                  <div className="ptdashboard-customer-header-title">
                    <span className="ptdashboard-customer-header-label-title1">
                      Payment
                    </span>
                  </div>
                </div>

                <MaterialTable data={aeternalPlanSched} columns={columns} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
