import React from "react";
import "./PartnersFooter.css";
export default function PartnersFooter() {
  return (
    <div className="common-partners-wrapper">
      <span className="common-partners-titles">PARTNERS</span>
      <div className="common-partners-image">
        <div className="common-partners-contents">
          <div className="common-partners-item">
            <img
              src={`assets/common/partners/google-review.png`}
              title=""
              alt="aeternalpaws-googlereview"
            />
          </div>
          <div className="common-partners-item">
            <img
              src={`assets/common/partners/ap-logo.png`}
              title=""
              alt="aeternalpaws-apsoftware"
            />
          </div>

          {/* <div className="common-partners-item">
            <img
              src={`assets/common/partners/ap-gold.png`}
              title=""
              alt="aeternalpaws-ap-gold"
            />
          </div> */}
          <div className="common-partners-item">
            <img
              src={`assets/common/partners/pangan-logo.png`}
              title=""
              alt="aeternalpaws-psychwell"
            />
          </div>
          {/* <div className="common-partners-item">
            <img src={`assets/common/partners/hao.png`} title="" alt="hao" />
          </div> */}
          <div className="common-partners-item">
            <img
              src={`assets/common/partners/awari.png`}
              title=""
              alt="aeternalpaws-awari"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
