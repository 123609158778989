import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import Login from "../../common/login/Login";
import { GetWhQtyLogin } from "../../helpers/ApiHelper";
import WarehouseInventoryDashboard from "./warehouseInventoryDashboard";

export default function WarehouseInventory() {
  const [aeternalObj, setAeternalObj] = useState([]);

  async function OnSubmit(user, pass) {
    try {
      await GetWhQtyLogin(user, pass).then((items) => {
        if (items == undefined) return;
        setAeternalObj(items.data);
      });
    } catch {}
  }

  const RenderBody = () => {
    if (aeternalObj.length == 0)
      return (
        <Login onHandleSubmit={OnSubmit} processPage={"warehouseinventory"} />
      );

    return <WarehouseInventoryDashboard aeternalObj={aeternalObj} />;
  };

  return (
    <>
      <ToastContainer />
      <div>{RenderBody()}</div>
    </>
  );
}
