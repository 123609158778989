import React from "react";

export default function AboutChooseUsImgHolder({ icon, title, subtitle }) {
  return (
    <div className="about-chooseus-image-content">
      <img
        src={`assets/about/${icon}.png`}
        title=""
        alt="history-banner"
        className={`aboutus-chooseus-image`}
      />
      <div className="about-chooseus-image-content-titlewrapper">
        <span className="about-chooseus-image-content-title">{title}</span>
        <span className="about-chooseus-image-content-subtitle">
          {subtitle}
        </span>
      </div>
    </div>
  );
}
