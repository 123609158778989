import "./index.css";
import "rsuite/dist/rsuite-no-reset.min.css";
import React from "react";
import ReactDOM from "react-dom/client";
import About from "./pages/about/About";
import Process from "./pages/process/Process";
import "bootstrap/dist/css/bootstrap.min.css";
import Services from "./pages/services/Services";
import ErrorPage from "./common/not-found/NotFound";
import PawTracker from "./pages/pawtracker/PawTracker";
import LandingPage from "./pages/landing-page/LandingPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AeternalPlan from "./pages/aeternalplan/AeternalPlan";
import Partner from "./pages/partner/Partner";
import AdminSales from "./pages/apadmin/AdminSales";
import WarehouseInventory from "./pages/warehouseinventory/WarehouseInventory";

const root = ReactDOM.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <ErrorPage />,
  },
  {
    path: "about",
    element: <About />,
  },
  {
    path: "process",
    element: <Process />,
  },
  {
    path: "services",
    element: <Services />,
  },
  {
    // path: "PawTracker/:barcode",
    path: "PawTracker",
    element: <PawTracker />,
  },
  {
    // path: "PawTracker/:barcode",
    path: "AeternalPlan",
    element: <AeternalPlan />,
  },
  {
    // path: "PawTracker/:barcode",
    path: "Partner",
    element: <Partner />,
  },
  {
    path: "warehouseInventory",
    element: <WarehouseInventory />,
  },
  {
    path: "AdminSales",
    element: <AdminSales />,
  },
  {
    path: "*",
    element: <LandingPage />,
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
