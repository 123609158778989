import React from "react";
import Carousel from "react-bootstrap/Carousel";
export default function AboutCarousel({
  WithIndicators = false,
  images,
  interval = 3000,
}) {
  return (
    <Carousel interval={interval} indicators={WithIndicators}>
      {images.map((n, i) => {
        return (
          <Carousel.Item key={i}>
            <img className="d-block w-100" src={n} alt={`${i} slide`} key={i} />
          </Carousel.Item>
        );
      })}
    </Carousel>
  );
}
