import "./HeroBanner.css";
import React from "react";

export default function HeroBanner({
  title,
  subtitle,
  desktopImage,
  mobileImage,
}) {
  return (
    <div className="common-herobanner-wrapper">
      <div className="common-herobanner-image">
        <img
          src={desktopImage}
          title=""
          alt="aeternalpaws-banner"
          className="common-desktop-herobanner-image"
        />
        <img
          src={mobileImage}
          title=""
          alt="aeternalpaws-banner"
          className="common-mobile-herobanner-image"
        />
        <div className="common-herobanner-content">
          <h1 className="common-herobanner-title">{title}</h1>
          <h2 className="common-herobanner-subtitle">{subtitle}</h2>
        </div>
      </div>
    </div>
  );
}
