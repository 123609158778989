import React from "react";

export default function ContactIcon({ img, title, description }) {
  //
  const renderClass = () => {
    if (img === "phone") return " phone";
    if (img === "time") return " time";
    return "";
  };

  const RenderARef = () => {
    if (img == "phone") {
      return <a href="tel:+639952610478">{description}</a>;
    }
    return description;
  };
  return (
    <div
      className={`common-contact-address${img === "address" ? " first" : ""}`}
    >
      <div className="common-contact-image-wrapper">
        <img
          src={`assets/common/contact/contact-desktop-${img}-icon.svg`}
          title=""
          alt={`aeternalpaws-${img}`}
          className="common-socialmedia-image"
        />
      </div>
      <div className="common-contact-address-content">
        {title !== "" && (
          <span className="common-contact-address-title">{title}</span>
        )}
        <span className={`common-contact-address-description${renderClass()}`}>
          <RenderARef />
        </span>
      </div>
    </div>
  );
}
