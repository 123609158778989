import axios from "axios";
import { toast } from "react-toastify";

//ito ung humahawak ng api Token Key.
let tokenKey = "";
const urlsting = GetApiUrl();

//headers ng API request. Requirement to ng dotnet core.
function InitializeHeaderParams(token) {
  const params = {
    headers: { Authorization: `Bearer ${token}` },
    params: { secretKey: "a3f4979a73d04dfd98fb212ff4768654" },
  };
  return params;
}

function GetApiUrl() {
  if (process.env.NODE_ENV.toLocaleLowerCase().includes("prod"))
    return process.env.REACT_APP_API_PROD;
  return process.env.REACT_APP_API_LOCAL;
}

//function that checks for the tokenKey.
async function InitializeApiHeader() {
  if (tokenKey === "") {
    tokenKey = await GetToken();
    if (tokenKey === undefined) {
      toast.error(`001: FAILED TO GET AUTHORIZATION`);
      return false;
    }
  }

  return true;
}

//function para i-log yung mga errors.
function HandleApiError(exObject, getTokenIfFailed = true) {
  //checks for 401 then try mag request ng new token
  if (
    exObject &&
    exObject.response &&
    exObject.response.status === 401 &&
    getTokenIfFailed
  )
    GetToken();

  console.error("ERROR: " + exObject);
  if (exObject && exObject.response && exObject.response.data) {
    const { errorMessage } = exObject.response.data;

    return toast.error(`ERROR: ${errorMessage}`);
  }
  toast.error(`SYSERROR: ${exObject.code}`);
}
export async function GetAeternalPlanData(barcode, password) {
  let dataToReturn = undefined;

  if (!(await InitializeApiHeader())) return;

  await axios
    .get(
      `${urlsting}/api/Aeternal/GetAeternalPlanData?barcode=${barcode}&password=${password}`,
      InitializeHeaderParams(tokenKey)
    )
    .then((n) => {
      dataToReturn = n;
    })
    .catch((e) => {
      HandleApiError(e);
    });

  return dataToReturn;
}
export async function GetPawTrackerData(barcode, password) {
  let dataToReturn = undefined;

  if (!(await InitializeApiHeader())) return;

  await axios
    .get(
      `${urlsting}/api/Aeternal/GetPawTrackerData?barcode=${barcode}&password=${password}`,
      InitializeHeaderParams(tokenKey)
    )
    .then((n) => {
      dataToReturn = n;
    })
    .catch((e) => {
      HandleApiError(e);
    });

  return dataToReturn;
}

const formatDateForCSharp = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // months are 0-based
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export async function GetApAdminSales(date) {
  let dataToReturn = undefined;

  if (!(await InitializeApiHeader())) return;

  await axios
    .get(
      `${urlsting}/api/Aeternal/GetApAdminSales?date=${formatDateForCSharp(
        date
      )}`,
      InitializeHeaderParams(tokenKey)
    )
    .then((n) => {
      dataToReturn = n;
    })
    .catch((e) => {
      HandleApiError(e);
    });

  return dataToReturn;
}
export async function GetWhQtyLogin(siteUser, sitePass) {
  let dataToReturn = undefined;

  if (!(await InitializeApiHeader())) return;

  await axios
    .get(
      `${urlsting}/api/Aeternal/getWarehouseInventory?siteUser=${siteUser}&sitePass=${sitePass}`,
      InitializeHeaderParams(tokenKey)
    )
    .then((n) => {
      dataToReturn = n;
    })
    .catch((e) => {
      HandleApiError(e);
    });

  return dataToReturn;
}
export async function GetApAdminSalesLogin(siteUser, sitePass) {
  let dataToReturn = undefined;

  if (!(await InitializeApiHeader())) return;

  await axios
    .get(
      `${urlsting}/api/Aeternal/GetApAdminSalesLogin?siteUser=${siteUser}&sitePass=${sitePass}`,
      InitializeHeaderParams(tokenKey)
    )
    .then((n) => {
      dataToReturn = n;
    })
    .catch((e) => {
      HandleApiError(e);
    });

  return dataToReturn;
}

export async function GetPartnerData(siteUser, sitePass) {
  let dataToReturn = undefined;

  if (!(await InitializeApiHeader())) return;

  await axios
    .get(
      `${urlsting}/api/Aeternal/GetPartnerData?siteUser=${siteUser}&sitePass=${sitePass}`,
      InitializeHeaderParams(tokenKey)
    )
    .then((n) => {
      dataToReturn = n;
    })
    .catch((e) => {
      HandleApiError(e);
    });

  return dataToReturn;
}

export async function GetToken() {
  const urlsting = GetApiUrl();

  const data = {
    Audience: "angelo",
    CustomClaims: {
      admin: "aeternaladmin",
    },
    Issuer: "aeternalmw.com",
    UserID: "",
    Mail: "admin@aeternalpaws.com",
  };

  let dataToReturn = undefined;

  await axios
    .post(
      `${urlsting}/api/Aeternal/GenerateToken`,
      data,
      InitializeHeaderParams(tokenKey)
    )
    .then((response) => {
      dataToReturn = response.data;
    })
    .catch((e) => {
      HandleApiError(e, false);
    });

  tokenKey = dataToReturn;
  return dataToReturn;
}
