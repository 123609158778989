import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import Login from "../../common/login/Login";
import { GetApAdminSalesLogin } from "../../helpers/ApiHelper";
import AdminSalesDashboard from "./dashboard/AdminSalesDashboard";

export default function AdminSales() {
  const [aeternalObj, setAeternalObj] = useState([]);

  async function OnSubmit(user, pass) {
    try {
      await GetApAdminSalesLogin(user, pass).then((items) => {
        if (items == undefined) return;
        setAeternalObj(items.data);
      });
    } catch {}
  }

  const RenderBody = () => {
    if (aeternalObj.length == 0)
      return <Login onHandleSubmit={OnSubmit} processPage={"apadmin"} />;

    return <AdminSalesDashboard data={aeternalObj} />;
  };

  return (
    <>
      <ToastContainer />
      <div>{RenderBody()}</div>
    </>
  );
}
