import React from "react";

export default function ProcessTiles({ img, number, title, subtitle }) {
  return (
    <div className="process-process-content-wrapper">
      <img
        src={`assets/process/process-desktop-${img}.png`}
        title=""
        alt="aeternalpaws-process"
        className="process-desktop-process-process-img"
      />

      <div className="process-process-steps-wrapper">
        <div className="process-process-steps-title-wrapper">
          <img
            src={`assets/process/process-desktop-${number}.png`}
            title=""
            alt="aeternalpaws-process"
            className="process-desktop-process-process-step-img"
          />
          <span className="process-process-steps-title">{title}</span>
        </div>
        <span className="process-process-steps-subtitle">{subtitle}</span>
      </div>
    </div>
  );
}
