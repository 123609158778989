import "./Contact.css";
import React from "react";
import ContactIcon from "./ContactIcon";

export default function Contact() {
  return (
    <div className="common-contact-wrapper">
      <div className="common-contact-image">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3856.7390458625628!2d120.85404267585811!3d14.839896785675313!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3396531f562459fb%3A0x521ef3e35151971c!2sAeternal%20Paws%20Pet%20Afterlife%20Services!5e0!3m2!1sen!2sph!4v1698139809963!5m2!1sen!2sph"
          allowFullScreen={false}
          loading="lazy"
          title="aeternalpaws-map"
          referrerPolicy="no-referrer-when-downgrade"
          className="common-contact-map"
        />

        <div className="common-contact-content">
          <span className="common-contact-title">Visit Us</span>

          <span className="common-contact-description">
            Located in the heart of the city - you'll find our sanctuary, always
            open and prepared to welcome your companions with open arms.
          </span>

          <span className="common-contact-socialmediaicon">
            <a
              href="https://www.facebook.com/aeternalpaws/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="assets/common/contact/contact-desktop-visit-fb-icon.svg"
                title=""
                alt="aeternalpaws-facebook"
                className="common-contact-socialmedia-image"
              />
            </a>
            <a
              href="https://www.instagram.com/AeternalPaws/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="assets/common/contact/contact-desktop-visit-ig-icon.svg"
                title=""
                alt="aeternalpaws-instagram"
                className="common-contact-socialmedia-image"
              />
            </a>
            <a
              href="https://www.tiktok.com/@AeternalPaws/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="assets/common/contact/contact-desktop-visit-tiktok-icon.svg"
                title=""
                alt="aeternalpaws-instagram"
                className="common-contact-socialmedia-image"
              />
            </a>
          </span>

          <ContactIcon
            title={<strong>Aeternal Paws</strong>}
            description="MacArthur Hwy, Brgy. Tikay, Malolos City, Bulacan"
            img="address"
          />
          <ContactIcon description="0995-261-0478" img="phone" />
          <ContactIcon description="inquiry@aeternalpaws.com" img="mail" />

          <ContactIcon
            title={
              <>
                <strong>Mon-Fri:</strong> 9am – 9pm
              </>
            }
            description={
              <>
                <strong>Weekends & Holidays:</strong> 9am – 9pm
              </>
            }
            img="time"
          />
        </div>
      </div>
    </div>
  );
}
