import React, { useMemo, useState, useEffect } from "react";
import NavBar from "../../common/navbar/NavBar";
import "./warehouseinventory.css";
import MaterialTable from "../aeternalplan/MaterialTable";
import { RenderQuantityColor } from "../../helpers/ClassHelper";
export default function WarehouseInventoryDashboard({ aeternalObj }) {
  const [tmpAeternalObj, setTmpAeternalObj] = useState([]);

  useEffect(() => {
    setTmpAeternalObj(aeternalObj.inventories);
  }, [aeternalObj]);

  const column = useMemo(
    () => [
      {
        accessorKey: "productCode",
        header: "Product Code",
        size: 130,
      },
      {
        accessorKey: "quantity",
        header: "Quantity",
        size: 110,
        Cell: ({ cell }) => (
          <div className={`rowTable ${RenderQuantityColor(cell.getValue())}`}>
            {cell.getValue()}
          </div>
        ),
      },
      {
        accessorKey: "longDescription",
        header: "Long Description",
        size: 260,
      },
      {
        accessorKey: "category",
        header: "Category",
        size: 150,
      },
    ],
    []
  );

  const onClickItemCount = () => {
    setTmpAeternalObj(aeternalObj.inventories);
  };

  const onClickCriticalItemCount = () => {
    const he = aeternalObj.inventories.filter(
      (n) => n.quantity > 0 && n.quantity < 10
    );
    setTmpAeternalObj(he);
  };

  const onClickNoQuantityCount = () => {
    const he = aeternalObj.inventories.filter((n) => n.quantity <= 0);
    setTmpAeternalObj(he);
  };

  return (
    <>
      <NavBar isFromPawTracker={true} />
      <div className="ptDashboard">
        <div className="ptdashboard-wrapper">
          <div className="pt-dashboard-details-wrapper whqty">
            <div className="ptdashboard-customer-wrapper">
              <div className="ptdashboard-customer-header-wrapper">
                <div className="ptdashboard-customer-header-title sales">
                  Inventory
                  <span className="ptdashboard-customer-header-label-title2 sales">
                    Inventory Breakdown
                  </span>
                </div>
                <div className={`ptdashboard-customer-header-subtitle`}>
                  WAREHOUSE
                </div>
              </div>
              {/* dqw */}
              <div
                className={`ptdashboard-customer-body-wrapper first`}
                onClick={() => onClickItemCount()}
              >
                <div className={`ptdashboard-customer-body-content-wrapper`}>
                  <div className={`ptdashboard-customer-body-content-label`}>
                    <span className={`ptdashboard-customer-body-content-title`}>
                      Total Item Count
                    </span>
                  </div>
                  <span className={`ptdashboard-customer-body-content-info`}>
                    {aeternalObj.inventories.length}
                  </span>
                </div>
              </div>

              <div
                className={`ptdashboard-customer-body-wrapper first`}
                onClick={() => onClickCriticalItemCount()}
              >
                <div className={`ptdashboard-customer-body-content-wrapper`}>
                  <div className={`ptdashboard-customer-body-content-label`}>
                    <span className={`ptdashboard-customer-body-content-title`}>
                      Critical Item
                    </span>
                  </div>
                  <span className={`ptdashboard-customer-body-content-info`}>
                    {
                      aeternalObj.inventories.filter(
                        (n) => n.quantity > 0 && n.quantity < 10
                      ).length
                    }
                  </span>
                </div>
              </div>

              <div
                className={`ptdashboard-customer-body-wrapper first`}
                onClick={() => onClickNoQuantityCount()}
              >
                <div
                  className={`ptdashboard-customer-body-content-wrapper${
                    true ? ` last` : ""
                  }`}
                >
                  <div className={`ptdashboard-customer-body-content-label`}>
                    <span className={`ptdashboard-customer-body-content-title`}>
                      out of stock
                    </span>
                  </div>
                  <span className={`ptdashboard-customer-body-content-info`}>
                    {
                      aeternalObj.inventories.filter((n) => n.quantity <= 0)
                        .length
                    }
                  </span>
                </div>
              </div>
              {/* dqw */}
            </div>
          </div>

          <div className="ptdashboard-timeline-wrapper">
            <div className="ptdashboard-timeline-wrapper-container">
              <div className="ptdashboard-customer-wrapper payment-history">
                <div className="ptdashboard-customer-header-wrapper">
                  <div className="ptdashboard-customer-header-title">
                    <span className="ptdashboard-customer-header-label-title1">
                      List of Inventory
                    </span>
                  </div>
                </div>

                <MaterialTable
                  data={tmpAeternalObj || []}
                  columns={column}
                  enableSorting={true}
                  enableTopToolbar={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
