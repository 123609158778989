import "./login.css";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import React, { useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";

export default function Login({ onHandleSubmit, processPage }) {
  const form = useRef();
  const { register, handleSubmit } = useForm();
  const [isLoading, setIsLoading] = useState(false);

  const from_password = "from_password";
  const from_barcode = "from_barcode";

  const onSubmit = async () => {
    if (isLoading)
      return toast.warning("We are still processing your login request.");

    setIsLoading(true);

    await onHandleSubmit(
      form.current[from_barcode].value,
      form.current[from_password].value
    );

    setIsLoading(false);
  };

  const RenderSubtitle = () => {
    if (processPage == "partner") return "Partner Referral Code *";
    if (processPage == "apadmin") return "Admin login code*";
    if (processPage == "warehouseinventory") return "warehouse login code*";
    return "Pet Identification Number *";
  };

  return (
    <>
      <div className="ptlogin">
        <div className="ptlogin-wrapper">
          <img
            src={`../assets/pawtracker/AP-light.png`}
            title=""
            alt="AP Software"
            className="ptlogin-apsoftware-img"
          />
          <div className="ptlogin-login-wrapper">
            <div className="ptlogin-login-content">
              <div className="ptlogin-login-content-header">
                <span className="ptlogin-login-content-title">Log In</span>
                <span className="ptlogin-login-content-subtitle">
                  Log in on the internal platform
                </span>
              </div>
              <div className="ptlogin-login-content-img">
                <img
                  src={`../assets/pawtracker/ap-logo-blue.png`}
                  title=""
                  alt="ap-logo"
                  className="ptlogin-aplogo-img"
                />
              </div>
            </div>

            <div className="ptlogin-login-body-content">
              <div className="ptlogin-login-body-form-content">
                <ToastContainer />
                <form ref={form} onSubmit={handleSubmit(onSubmit)}>
                  <input
                    width="100%"
                    placeholder={RenderSubtitle()}
                    {...register(from_barcode, {
                      required: true,
                      maxLength: 80,
                    })}
                    className="services-contact-fullwidth"
                    autoComplete="on"
                  />

                  <input
                    width="100%"
                    type="password"
                    placeholder="Password *"
                    {...register(from_password, {
                      required: true,
                      maxLength: 80,
                    })}
                    className="services-contact-fullwidth password"
                    autoComplete="off"
                  />

                  <input
                    type="submit"
                    value={isLoading ? "Logging In..." : "Log In"}
                    className="services-contact-body-content-button-detail-button"
                  />
                </form>
              </div>
              {/* <PawTrackerLoginForm barcode={barcode} OnSetObj={OnSetObj} /> */}
            </div>

            <div className="ptlogin-login-footer-content">
              <div className="ptlogin-login-footer-img">
                <img
                  src={`../assets/pawtracker/pawtracker-bookmark.png`}
                  title=""
                  alt="ap-logo"
                  className="ptlogin-bookmark-img"
                />
                <span className="ptlogin-login-footer-header">
                  Kindly use the password provided by the Aeternal Paws team!
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
