import "./PawTracker.css";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Login from "../../common/login/Login";
import PawTrackerDashboard from "./dashboard/PawTrackerDashboard";
import { GetPawTrackerData } from "../../helpers/ApiHelper";

export default function PawTracker() {
  const [aeternalObj, setAeternalObj] = useState([]);

  //comment muna for now - para to sa icon.
  // var link = document.querySelector("link[rel~='icon']");
  // if (!link) {
  //   link = document.createElement("link");
  //   link.rel = "icon";
  //   document.head.appendChild(link);
  // }
  // link.href = "../assets/tabicon-pinkblue-frame.png";

  async function OnSubmit(user, pass) {
    try {
      await GetPawTrackerData(user, pass).then((items) => {
        if (items == undefined) return;

        const { pet } = items.data;
        if (pet.healthStatus.toLowerCase() != "deceased")
          return toast.warning(
            "Your pet's health status is still active. Please contact the admin if you think this is not correct"
          );

        setAeternalObj(items);
      });
    } catch {}
  }

  const RenderBody = () => {
    if (aeternalObj.length == 0) return <Login onHandleSubmit={OnSubmit} />;

    return <PawTrackerDashboard data={aeternalObj} />;
  };
  return (
    <>
      <ToastContainer />
      <div>{RenderBody()}</div>
    </>
  );
}
