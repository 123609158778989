import React, { useState } from "react";
import LandingPageServices from "./../landing-page/LandingPageServices";
import GenericModal from "../../common/GenericFunction/GenericModal";

export default function ServicesList({ img, title, subtitle, content }) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="services-list-wrapper">
      <GenericModal
        openModal={openModal}
        setOpenModal={setOpenModal}
        content={content}
      />
      <img
        src={`assets/services/services-list-${img}.png`}
        title=""
        alt="aeternalpaws-services"
        className="services-services-list-img"
      />
      <LandingPageServices
        title={title}
        subtitle={subtitle}
        setOpenModal={setOpenModal}
      />
    </div>
  );
}
