import NavBar from "../../../common/navbar/NavBar";
import MaterialTable from "../../aeternalplan/MaterialTable";
import PawTrackerDashboardList from "../../pawtracker/dashboard/PawTrackerDashboardList";
import {
  RenderStatusColor,
  ValueIsBooleanTrue,
} from "./../../../helpers/ClassHelper";
import React, { useMemo } from "react";

export default function PartnerDashboard({ data }) {
  const { referrals, partner, orders } = data.data;

  const ordersColumn = useMemo(
    () => [
      {
        accessorKey: "orderStatus", //access nested data with dot notation
        header: "Order Status",
        size: 180,
        Cell: ({ cell }) => (
          <div className={`rowTable ${RenderStatusColor(cell.getValue())}`}>
            {cell.getValue()}
          </div>
        ),
      },
      {
        accessorKey: "id",
        header: "Record ID",
        size: 150,
      },
      {
        accessorKey: "custName",
        header: "Customer",
        size: 200,
      },
      {
        accessorKey: "petName",
        header: "Pet",
        size: 150,
      },
      {
        accessorKey: "longDescription", //normal accessorKey
        header: "Order Item",
        size: 250,
      },
      {
        accessorKey: "remark", //normal accessorKey
        header: "Order Remark",
        size: 250,
      },
      {
        accessorKey: "quantity",
        header: "Quantity",
        size: 150,
      },
      {
        accessorKey: "vendorUnitPrice",
        header: "Vendor Price",
        size: 150,
      },
      {
        accessorKey: "netAmount",
        header: "Net Amount",
        size: 150,
      },
      {
        accessorKey: "lastStatusUpdateDate",
        header: "Last Update Date",
        size: 200,
      },
      {
        accessorKey: "transheader", //normal accessorKey
        header: "Trans #",
        size: 170,
      },
    ],
    []
  );

  const referralsColumn = useMemo(
    () => [
      {
        accessorKey: "status", //access nested data with dot notation
        header: "Order Status",
        size: 190,
        Cell: ({ cell }) => (
          <div className={`rowTable ${RenderStatusColor(cell.getValue())}`}>
            {cell.getValue()}
          </div>
        ),
      },
      {
        accessorKey: "transid", //normal accessorKey
        header: "Trans #",
        size: 170,
      },
      {
        accessorKey: "type", //normal accessorKey
        header: "Trans Type",
        size: 190,
      },
      {
        accessorKey: "amount",
        header: "Referral Amount",
        size: 150,
      },
      {
        accessorKey: "paymentDate",
        header: "Payment Date",
        size: 150,
      },
      {
        accessorKey: "paymentReference",
        header: "Payment Reference",
        size: 150,
      },
      {
        accessorKey: "lastStatusUpdateDate",
        header: "Last Update Date",
        size: 200,
      },
      {
        accessorKey: "createdDateTime",
        header: "Created Date",
        size: 200,
      },
      {
        accessorKey: "id",
        header: "Record ID",
        size: 150,
      },
    ],
    []
  );

  return (
    <>
      <NavBar isFromPawTracker={true} />
      <div className="ptDashboard">
        <div className="ptdashboard-wrapper">
          <div className="pt-dashboard-details-wrapper">
            <div className="ptdashboard-customer-wrapper">
              <div className="ptdashboard-customer-header-wrapper">
                <div className="ptdashboard-customer-header-title">
                  {partner.partnerName}
                  <span className="ptdashboard-customer-header-label-title2">
                    {partner.partnerType}
                  </span>
                </div>
                <div className={`ptdashboard-customer-header-subtitle`}>
                  {partner.partnerCode}
                </div>
              </div>
              <PawTrackerDashboardList
                first={true}
                label={"Mobile"}
                info={partner.mobile}
                img={"smartphone"}
              />
              <PawTrackerDashboardList
                label={"Email"}
                info={partner.email}
                img={"email"}
              />
              <PawTrackerDashboardList
                label={"Method of Payment"}
                info={partner.methodOfPayment}
                img={"wallet"}
              />
              <PawTrackerDashboardList
                label={"Account Number"}
                info={partner.accountNumber}
                img={"accountNumber"}
                last={true}
              />
              <div className="ptdashboard-customer-footer-wrapper">
                {ValueIsBooleanTrue(partner.allowVendor) && (
                  <div>
                    <div className="ptdashboard-customer-footer-title">
                      {partner.totalOrder}
                    </div>
                    <div className="ptdashboard-customer-footer-subtitle">
                      Total Orders
                    </div>
                  </div>
                )}
                {ValueIsBooleanTrue(partner.allowReferral) && (
                  <div>
                    <div className="ptdashboard-customer-footer-title">
                      {partner.totalReferral}
                    </div>
                    <div className="ptdashboard-customer-footer-subtitle">
                      Total Referrals
                    </div>
                  </div>
                )}
              </div>
            </div>
            {/* order billing table */}
            {ValueIsBooleanTrue(partner.allowVendor) && (
              <div className="ptdashboard-customer-wrapper">
                <div className="ptdashboard-customer-header-wrapper">
                  <div className="ptdashboard-customer-header-title">
                    <span className="ptdashboard-customer-header-label-title1">
                      Order Billing
                    </span>
                  </div>
                </div>

                <PawTrackerDashboardList
                  label={"Orders Amount Paid"}
                  info={partner.orderAmountPaid}
                  first={true}
                />
                <PawTrackerDashboardList
                  label={"Orders Amount Unpaid"}
                  info={partner.orderAmountUnPaid}
                  last={true}
                  renderStatus={true}
                />
              </div>
            )}

            {/* referrals table */}
            {ValueIsBooleanTrue(partner.allowReferral) && (
              <div className="ptdashboard-customer-wrapper">
                <div className="ptdashboard-customer-header-wrapper">
                  <div className="ptdashboard-customer-header-title">
                    <span className="ptdashboard-customer-header-label-title1">
                      Referral Billing
                    </span>
                  </div>
                </div>
                <PawTrackerDashboardList
                  label={"Referral Amount Paid"}
                  info={partner.referralAmountPaid}
                  first={true}
                />
                <PawTrackerDashboardList
                  label={"Referral Amount Unpaid"}
                  info={partner.referralAmountUnPaid}
                  last={true}
                  renderStatus={true}
                />
              </div>
            )}
          </div>

          <div className="ptdashboard-timeline-wrapper">
            <div className="ptdashboard-timeline-wrapper-container">
              {/* order table */}
              {ValueIsBooleanTrue(partner.allowVendor) && (
                <div className="ptdashboard-customer-wrapper payment-history">
                  <div className="ptdashboard-customer-header-wrapper">
                    <div className="ptdashboard-customer-header-title">
                      <span className="ptdashboard-customer-header-label-title1">
                        Orders
                      </span>
                    </div>
                  </div>

                  <MaterialTable
                    data={orders}
                    columns={ordersColumn}
                    enableBottomToolbar={true}
                    enablePagination={true}
                  />
                </div>
              )}

              {/* referral table */}
              {ValueIsBooleanTrue(partner.allowReferral) && (
                <div className="ptdashboard-customer-wrapper payment-history">
                  <div className="ptdashboard-customer-header-wrapper">
                    <div className="ptdashboard-customer-header-title">
                      <span className="ptdashboard-customer-header-label-title1">
                        Referrals
                      </span>
                    </div>
                  </div>

                  <MaterialTable
                    data={referrals}
                    columns={referralsColumn}
                    enableBottomToolbar={true}
                    enablePagination={true}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
