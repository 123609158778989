export function GetServiceContent(content) {
  if (content == "plan") return AeternalPlan;
  if (content == "memorial") return Memorial;
  if (content == "sea") return Sea;
  if (content == "photog") return Photog;
  if (content == "pickup") return Pickup;
  if (content == "grief") return Grief;
  if (content == "personalize") return Personalize;
  if (content == "cremation") return Cremation;
}

const AeternalPlan = (
  <>
    It is never easy to accept losing a loved one, as it is an inevitable part
    of life. However, at Aeternal Paws, we deeply sympathize with the fur
    parents as they process their pet’s loss. So, to better execute our roles to
    support you, we’ve created “Aeternal Pet Life Planning,” designed to ensure
    your pet’s memorial farewell.
    <br />
    <br />
    Take the first step and learn more about it including it's flexible
    financial terms by sending a message or calling our care line. We are
    committed to giving what's best for any fur family.
  </>
);

const Memorial = (
  <>
    In order to support pet parents during this difficult time, our dedicated
    team will arrange a serene setting where they can share precious moments
    with their beloved companions in their final hours. We also welcome the
    opportunity for family and friends to join in and be part of this
    significant farewell.
  </>
);
const Cremation = (
  <>
    The team takes great care to meticulously manage each stage of the process
    with the utmost respect. Our Paw Tracker, a system for pet identification,
    guarantees that your pet's journey with us culminates with the correct ashes
    in your hands. Moreover, it offers you the convenience of staying informed
    about your pet's status during their time with us.
  </>
);
const Sea = (
  <>
    We have a designated location at Subic and Batangas with chartered boats for
    fur families to scatter their beloved pet’s ashes into the sea.
  </>
);
const Photog = (
  <>
    Leave all the tasks of capturing documentaries to our team of professionals,
    allowing you to concentrate on bidding a heartfelt farewell to your beloved
    pet. Your cherished photos and videos will be securely stored on a hardware,
    ready for retrieval when you collect your pet's ashes and other keepsakes.
  </>
);
const Pickup = (
  <>
    Should there be any obstacles or challenges related to transportation, our
    team is always prepared to lend a helping hand to fur families facing
    difficulties in moving their beloved pets to our location.
  </>
);
const Grief = (
  <>
    Even after completing all the procedures of cremation and collection of
    keepsakes, the Aeternal Paws team will remain steadfast with the fur
    families to guide and support them through their grief.
  </>
);
const Personalize = (
  <>
    As loving pet parents, we deeply empathize with the desire to cherish the
    memories of our beloved companions. In our carefully curated selection of
    keepsakes, you will discover a meaningful memento that not only captures
    your pet's distinctive qualities but also honors the exceptional bond you
    shared.
  </>
);
