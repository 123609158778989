import React from "react";

export default function AboutImageSlide({
  imageName,
  fullImgHeight = false,
  RightControlPosition = true,
  showControls = false,
}) {
  return (
    <>
      <img
        src={`assets/about/${imageName}.png`}
        title=""
        alt={`history-banner-${imageName}`}
        className={`aboutus-history-image${
          fullImgHeight === true ? " height" : ""
        }`}
      />
      {showControls && (
        <div
          className={`aboutus-history-image-controls ${
            RightControlPosition ? " right" : ""
          }`}
        >
          <img
            src="assets/common/images/images-arrow-left.png"
            title=""
            alt={`aeternalpaws-left"-${imageName}`}
            className="aboutus-history-arrow"
          />
          <img
            src="assets/common/images/images-arrow-right.png"
            title=""
            alt={`aeternalpaws-right"-${imageName}`}
            className="aboutus-history-arrow"
          />
        </div>
      )}
    </>
  );
}
