import "./AeternalPlan.css";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import Login from "../../common/login/Login";
import { GetAeternalPlanData } from "../../helpers/ApiHelper";
import AeternalPlanDashboard from "./AeternalPlanDashboard";

export default function AeternalPlan() {
  const [aeternalObj, setAeternalObj] = useState([]);

  async function OnSubmit(user, pass) {
    try {
      await GetAeternalPlanData(user, pass).then((items) => {
        if (items == undefined) return;

        setAeternalObj(items);
      });
    } catch {}
  }

  const RenderBody = () => {
    if (aeternalObj.length == 0) return <Login onHandleSubmit={OnSubmit} />;

    return <AeternalPlanDashboard data={aeternalObj} />;
  };
  return (
    <>
      <ToastContainer />
      <div>{RenderBody()}</div>
    </>
  );
}
