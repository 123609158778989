import React from "react";
import { MaterialReactTable } from "material-react-table";

export default function MaterialTable({
  data,
  columns,
  enablePagination = false,
  enableBottomToolbar = false,
  enableTopToolbar = false,
  enableSorting = false,
}) {
  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enablePagination={enablePagination}
      enableSorting={enableSorting}
      enableTopToolbar={enableTopToolbar}
      enableBottomToolbar={enableBottomToolbar}
      initialState={{ density: "compact" }}
    />
  );
}

//nested data is ok, see accessorKeys in ColumnDef below
//   const data = [
//     {
//       name: {
//         firstName: "John",
//         lastName: "Doe",
//       },
//       address: "261 Erdman Ford",
//       city: "East Daphne",
//       state: "Kentucky",
//     },
//   ];

//   const columns = useMemo(
//     () => [
//       {
//         accessorKey: "name.firstName", //access nested data with dot notation
//         header: "Due Date",
//         size: 150,
//       },
//       {
//         accessorKey: "createdDate",
//         header: "Payment Date",
//         size: 150,
//       },
//     ],
//     []
//   );
