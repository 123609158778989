import React from "react";
import "./Footer.css";

export default function Footer() {
  return (
    <div className="common-footer-wrapper">
      <span className="common-footer-image">
        © 2023 Aeternal Paws Pet Afterlife Services. All Rights Reserved.
      </span>
    </div>
  );
}
