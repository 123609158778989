export const RenderQuantityColor = (quantity) => {
  quantity = quantity.toLowerCase();

  //for numbers
  try {
    let amt = parseFloat(quantity).toFixed(2);
    if (amt <= 0) return "red";
    if (amt < 10) return "orange";
    return "green";
  } catch (error) {
    return "green";
  }
};

export const RenderStatusColor = (planStatus) => {
  planStatus = planStatus.toLowerCase();

  if (
    planStatus === "active" ||
    planStatus === "00.00" ||
    planStatus === "0.00" ||
    planStatus === "delivered" ||
    planStatus === "ordered" ||
    planStatus === "approved"
  )
    return "green";

  if (
    planStatus === "terminated" ||
    planStatus === "overdue" ||
    planStatus === "issue" ||
    planStatus === "rejected"
  )
    return "red";

  if (
    planStatus === "chasing" ||
    planStatus === "unpaid" ||
    planStatus === "review" ||
    planStatus === "pending"
  )
    return "orange";

  if (
    planStatus === "skipped" ||
    planStatus === "claimed" ||
    planStatus === "paid" ||
    planStatus === "disbursed"
  )
    return "blue";

  //for numbers
  try {
    let amt = parseFloat(planStatus).toFixed(2);
    if (amt > 0) return "orange";
  } catch (error) {
    return "orange";
  }
  return "orange";
};

export const ValueIsBooleanTrue = (value) => {
  if (value == undefined || value == null || value == "") return false;

  value = value.toLowerCase();
  if (value == "false") return false;

  return true;
};
