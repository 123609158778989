import "./navbar.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function NavBar({ ActiveButton, isFromPawTracker = false }) {
  const [HideMenuSelection, setHideMenuSelection] = useState(true);

  const RenderActiveButton = (text) => (text === ActiveButton ? " active" : "");

  const HandleIconClick = () => setHideMenuSelection(!HideMenuSelection);

  const RenderImgUrl = (url) => (isFromPawTracker ? "../" + url : url);

  return (
    <>
      <div className="navbar-page">
        <div className="navbar-contact">
          <div className="navbar-contact-phone">
            <img
              src={RenderImgUrl(`assets/common/navbar/navbar-call-icon.png`)}
              title=""
              alt="aeternalpaws-email-icon"
              className="navbar-imgicons"
            />
            <span>
              <a href="tel:+639952610478">0995-261-0478</a>
            </span>
          </div>
          <div className="navbar-contact-email">
            <img
              src={RenderImgUrl(`assets/common/navbar/navbar-mail-icon.png`)}
              title=""
              alt="aeternalpaws-email-icon"
              className="navbar-imgicons"
            />

            <span>inquiry@aeternalpaws.com</span>
          </div>
        </div>
        <div className="navbar-navbuttons">
          <div className="navbar-logo">
            <Link to={`/`}>
              <img
                src={RenderImgUrl("assets/common/navbar/navbar-icon.png")}
                alt="aeternalpaws-logo"
                title="Aeternal Paws"
              />
            </Link>
          </div>
          <div className="navbar-buttons">
            <div className="navbar-buttons-menuicon">
              <img
                onClick={() => HandleIconClick()}
                className={
                  HideMenuSelection ? "" : "navbar-buttons-icon hidden"
                }
                src={RenderImgUrl("assets/common/navbar/navbar-menu-icon.svg")}
                title="Menu Bar"
                alt="aeternalpaws-menu-icon"
              />
              <img
                onClick={() => HandleIconClick()}
                className={
                  HideMenuSelection ? "navbar-buttons-icon hidden" : ""
                }
                src={RenderImgUrl("assets/common/navbar/navbar-close-icon.svg")}
                title="Close Menu Bar"
                alt="aeternalpaws-menu-icon"
              />
            </div>
            <div
              className={
                HideMenuSelection
                  ? "navbar-buttons-labels hidden"
                  : "navbar-buttons-labels"
              }
            >
              <ul>
                <li className={RenderActiveButton("home")}>
                  <Link to={`/`}>home</Link>
                </li>
                <li className={RenderActiveButton("about")}>
                  <Link to={`/about`}>about</Link>
                </li>
                <li className={RenderActiveButton("process")}>
                  <Link to={`/process`}>process</Link>
                </li>
                <li className={RenderActiveButton("services")}>
                  <Link to={`/services`}>services</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
