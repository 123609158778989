export const AboutImgs = {
  Acredited: [
    "assets/about/about-accreditation-iccfa.png",
    "assets/about/about-accreditation-plpa.png",
  ],
  OurClinic: [
    "assets/about/carousel1.png",
    "assets/about/carousel2.png",
    "assets/about/carousel3.png",
  ],
  Austine: [
    "assets/about/austine/austine1.jpg",
    "assets/about/austine/austine2.jpg",
    "assets/about/austine/austine3.jpg",
    "assets/about/austine/austine4.jpg",
    "assets/about/austine/austine5.jpg",
    "assets/about/austine/austine6.jpg",
    "assets/about/austine/austine7.jpg",
    "assets/about/austine/austine9.jpg",
    "assets/about/austine/austine10.jpg",
  ],
};
