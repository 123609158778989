import React from "react";
import "./RowIcon.css";

export default function RowIcon({
  title,
  titleSmall,
  ic1,
  lbl1,
  ic2,
  lbl2,
  ic3,
  lbl3,
  ic4,
  lbl4,
  ic5,
  lbl5,
}) {
  return (
    <div className="common-rowicon-wrapper">
      <div className="common-rowicon-image">
        <div className="common-rowicon-titlebox">
          {titleSmall && (
            <span className="common-rowicon-titlebox-titlesmall">
              {titleSmall}
            </span>
          )}
          <span className="common-rowicon-titlebox-title">{title}</span>
        </div>
        <div className="common-row-icon-contents">
          <div className="common-row-icon-item">
            <img
              src={`assets/common/row-icon/row-icon-${ic1}.png`}
              title=""
              alt={`aeternalpaws-${ic1}`}
            />
            <span className="common-row-icon-itemtitle">{lbl1}</span>
          </div>
          <div className="common-row-icon-item">
            <img
              src={`assets/common/row-icon/row-icon-${ic2}.png`}
              title=""
              alt={`aeternalpaws-${ic1}`}
            />
            <span className="common-row-icon-itemtitle">{lbl2}</span>
          </div>
          <div className="common-row-icon-item">
            <img
              src={`assets/common/row-icon/row-icon-${ic3}.png`}
              title=""
              alt={`aeternalpaws-${ic1}`}
            />
            <span className="common-row-icon-itemtitle">{lbl3}</span>
          </div>
          <div className="common-row-icon-item">
            <img
              src={`assets/common/row-icon/row-icon-${ic4}.png`}
              title=""
              alt={`aeternalpaws-${ic1}`}
            />
            <span className="common-row-icon-itemtitle">{lbl4}</span>
          </div>
          <div className="common-row-icon-item">
            <img
              src={`assets/common/row-icon/row-icon-${ic5}.png`}
              title=""
              alt={`aeternalpaws-${ic1}`}
            />
            <span className="common-row-icon-itemtitle">{lbl5}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
