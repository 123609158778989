import React from "react";

export default function ServicesPackageList({ title }) {
  return (
    <div className="services-pricing-body-list-wrapper">
      <img
        src="assets/services/services-check-icon.png"
        title=""
        alt="aeternalpaws-banner"
        className="services-pricing-body-list-icon"
      />
      <span className="services-pricing-body-list-span">{title}</span>
    </div>
  );
}
