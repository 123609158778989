import NavBar from "../../../common/navbar/NavBar";
import PawTrackerDashboardList from "../../pawtracker/dashboard/PawTrackerDashboardList";
import React, { useState, useEffect } from "react";
import "../apadminsales.css";
import { DatePicker } from "rsuite";
import { GetApAdminSales } from "../../../helpers/ApiHelper";

export default function AdminSalesDashboard({ data }) {
  const [aeternalObj, setAeternalObj] = useState({});

  const handleChange = (value) => {
    try {
      GetApAdminSales(value).then((items) => {
        if (items == undefined) return;
        setAeternalObj(items.data);
      });
    } catch {}
  };
  useEffect(() => {
    if (data) {
      setAeternalObj(data);
    }
  }, [data]);

  if (!aeternalObj || Object.keys(aeternalObj).length === 0) {
    return <div>Loading...</div>; // or any other loading indicator
  }

  return (
    <>
      <NavBar isFromPawTracker={true} />
      <div className="ptDashboard">
        <div className="ptdashboard-wrapper">
          <div className="pt-dashboard-details-wrapper">
            <div className="ptdashboard-customer-wrapper">
              <div className="ptdashboard-customer-header-wrapper">
                <div className="ptdashboard-customer-header-title sales">
                  ₱{aeternalObj.totalSales}
                  <span className="ptdashboard-customer-header-label-title2 sales">
                    {aeternalObj.date}
                  </span>
                </div>
                <div className={`ptdashboard-customer-header-subtitle`}>
                  Overall Sales
                </div>
              </div>
              {aeternalObj.breakdowns.map((n, index) => {
                return (
                  <PawTrackerDashboardList
                    key={index}
                    label={n.name}
                    first={index === 0}
                    info={`₱${n.amount}`}
                    last={index === aeternalObj.breakdowns.length - 1}
                  />
                );
              })}
            </div>

            <DatePicker
              size="lg"
              placeholder="Sales Date"
              block
              format="MMMM dd, yyyy"
              defaultValue={new Date()}
              onChange={handleChange}
            />
          </div>

          <div className="ptdashboard-timeline-wrapper">
            <div className="ptdashboard-timeline-wrapper-container">
              {/* order table */}
              {aeternalObj.branches.map((n, branchIndex) => {
                return (
                  <div
                    key={branchIndex}
                    className="ptdashboard-customer-wrapper payment-history"
                  >
                    <div className="ptdashboard-customer-header-wrapper">
                      <div className="ptdashboard-customer-header-title sales">
                        ₱{n.totalSales}
                        <span className="ptdashboard-customer-header-label-title2 sales">
                          total sales
                        </span>
                      </div>
                      <div className={`ptdashboard-customer-header-subtitle`}>
                        {n.branchname}
                      </div>
                    </div>
                    {n.salesbreakdown.map((n, index) => {
                      return (
                        <PawTrackerDashboardList
                          label={n.name}
                          first={index === 0}
                          info={`₱${n.amount}`}
                          last={index === aeternalObj.breakdowns.length - 1}
                        />
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
