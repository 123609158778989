import React from "react";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import PawTrackerDashboardTimelineElement from "./PawTrackerDashboardTimelineElement";

export default function PawTrackerDashboardTimeline({ data, petName }) {
  return (
    <VerticalTimeline>
      <VerticalTimelineElement
        iconStyle={{ background: "rgb(17, 54, 90)", color: "#fff" }}
      />

      {data.data.petTimeline.map((n) => {
        return (
          <PawTrackerDashboardTimelineElement
            key={n.id}
            date={n.createdDateTime}
            label={n.status}
            petName={petName}
          />
        );
      })}
    </VerticalTimeline>
  );
}
