import React from "react";
import Modal from "react-modal";
import "./GenericModal.css";
export default function GenericModal({ openModal, setOpenModal, content }) {
  function closeModal() {
    setOpenModal(false);
  }

  return (
    <div>
      <Modal
        isOpen={openModal}
        onRequestClose={() => closeModal()}
        contentLabel="Example Modal"
        className="genericModal-parentWrapper"
      >
        <div className="genericModal-wrapper">
          <div className="genericModal-content">{content}</div>
        </div>
      </Modal>
    </div>
  );
}
