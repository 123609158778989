import React from "react";
import { RenderStatusColor } from "./../../../helpers/ClassHelper";

export default function PawTrackerDashboardList({
  img,
  label,
  info,
  last,
  first,
  renderStatus = false,
}) {
  return (
    <div
      className={`ptdashboard-customer-body-wrapper${first ? ` first` : ""}`}
    >
      <div
        className={`ptdashboard-customer-body-content-wrapper${
          last ? ` last` : ""
        }`}
      >
        <div className={`ptdashboard-customer-body-content-label`}>
          {img && (
            <img
              src={`../assets/pawtracker/${img}.png`}
              title=""
              alt="phone"
              className="ptdashboard-customer-body-img"
            />
          )}
          <span className={`ptdashboard-customer-body-content-title`}>
            {label}
          </span>
        </div>
        <span
          className={`ptdashboard-customer-body-content-info ${
            renderStatus && RenderStatusColor(info)
          }`}
        >
          {info}
        </span>
      </div>
    </div>
  );
}
