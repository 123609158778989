import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { useForm } from "react-hook-form";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

export default function ServicesForm() {
  const { register, handleSubmit } = useForm();
  const form = useRef();

  const onSubmit = () => {
    emailjs
      .sendForm(
        "service_t8m4f6n",
        "template_o9qekzg",
        form.current,
        "ckkNfC6Uun-Adad6c"
      )
      .then(
        () => {
          toast.success("Inquiry Sent Successfully.", {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        },
        (error) => {
          toast.error(`ERROR sending inquiry: ${error.text}`, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      );
  };

  return (
    <>
      <ToastContainer />
      <form ref={form} onSubmit={handleSubmit(onSubmit)}>
        <input
          width="100%"
          type="text"
          placeholder="Name *"
          {...register("from_name", { required: true, maxLength: 80 })}
          className="services-contact-fullwidth"
        />
        <div className="services-contact-mobile-wrapper">
          <input
            name="from_email"
            type="text"
            placeholder="Email Address *"
            className="services-contact-halfwidth"
            {...register("from_email", {
              required: true,
            })}
          />
          <input
            name="from_phone"
            type="text"
            className="services-contact-halfwidth"
            placeholder="Phone Number *"
            {...register("from_phone", {
              required: true,
            })}
          />
        </div>
        <select
          name="from_type"
          className="services-contact-fullwidth"
          {...register("from_type", { required: true })}
        >
          <option value="General Inquiry">General Inquiry</option>
          <option value="Product Inquiry">Product Inquiry</option>
          <option value="Service Inquiry">Service Inquiry</option>
          <option value="Scheduling Inquiry">Scheduling Inquiry</option>
        </select>
        <input
          name="message"
          type="text"
          className="services-contact-fullwidth multiline"
          placeholder="Your Message Here... *"
          {...register("message", { required: true, maxLength: 300 })}
        />

        <div className="services-contact-body-content-button">
          <div className="services-contact-body-content-button-detail">
            <img
              src={`assets/services/services-desktop-contactform-call-icon.png`}
              title=""
              alt="aeternalpaws-services"
              className="services-desktop-contactform-call-icon"
            />
            <img
              src={`assets/services/services-mobile-contactform-call-icon.png`}
              title=""
              alt="aeternalpaws-services"
              className="services-mobile-contactform-call-icon"
            />
            <div className="services-contact-body-content-button-detail-text-wrapper">
              <span className="services-contact-body-content-button-detail-title">
                For Faster Response, Call Us!
              </span>
              <span className="services-contact-body-content-button-detail-subtitle">
                <a href="tel:+639952610478">0995-261-0478 </a>
              </span>
            </div>
          </div>
          <input
            type="submit"
            value="SUBMIT INQUIRY"
            className="services-contact-body-content-button-detail-button"
          />
        </div>
      </form>
    </>
  );
}
