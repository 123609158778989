import React from "react";

export default function ProcessQuery({
  question,
  answer,
  id,
  activeFaq,
  setActive,
}) {
  const RenderButtonClass = () => (id === activeFaq ? "up" : "down");
  const RenderActiveClass = () => (id === activeFaq ? " active" : "");
  return (
    <div className="process-query-query-wrapper">
      <div
        className={`process-query-queries-title-wrapper${RenderActiveClass()}`}
      >
        <span className={`process-query-queries-title`}>{question}</span>
        <img
          onClick={() => setActive(id)}
          src={`assets/process/process-desktop-faq-arrow-${RenderButtonClass()}.png`}
          title=""
          alt="aeternalpaws-control"
          className="process-query-queries-img"
        />
      </div>
      <span
        className={`process-query-queries-description${RenderActiveClass()}`}
      >
        {answer}
      </span>
    </div>
  );
}
