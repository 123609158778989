import "./Partner.css";
import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import Login from "../../common/login/Login";
import { GetPartnerData } from "../../helpers/ApiHelper";
import PartnerDashboard from "./dashboard/PartnerDashboard";

export default function Partner() {
  const [aeternalObj, setAeternalObj] = useState([]);

  async function OnSubmit(user, pass) {
    try {
      await GetPartnerData(user, pass).then((items) => {
        if (items == undefined) return;

        setAeternalObj(items);
      });
    } catch {}
  }

  const RenderBody = () => {
    if (aeternalObj.length == 0) return <Login onHandleSubmit={OnSubmit} processPage={"partner"} />;

    return <PartnerDashboard data={aeternalObj} />;
  };
  return (
    <>
      <ToastContainer />
      <div>{RenderBody()}</div>
    </>
  );
}
