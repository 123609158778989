import React from "react";
import { useNavigate } from "react-router-dom";

export default function LandingPageServices({
  title,
  subtitle,
  setOpenModal,
  PerformRedirect = false,
}) {
  const navigate = useNavigate();

  const HandlePerformRedirect = () => {
    if (PerformRedirect) {
      navigate("/services");
      window.scrollTo(0, 0);
      return;
    }
    setOpenModal(true);
  };
  return (
    <div className={`homepage-services-service`}>
      <span className="homepage-services-servicetitle">{title}</span>
      <span className="homepage-services-servicesubtitle">{subtitle}</span>
      <span
        className="homepage-services-servicereadmore"
        onClick={HandlePerformRedirect}
      >
        <span>read more</span>

        <img
          src="assets/homepage/homepage-service-play-icon.svg"
          title="Menu Bar"
          alt="aeternalpaws-menu-icon"
        />
      </span>
    </div>
  );
}
