import React from "react";
import ServicesPackageList from "./../ServicesPackageList";

export default function PackageThree() {
  return (
    <div className="services-pricing-body-list">
      <div className="services-pricing-body-title-wrapper">
        <span className="services-pricing-body-title">our premium package</span>
        <span className="services-pricing-body-subtitle">deluxe</span>
      </div>
      <div className="services-pricing-body-wrapper">
        <div className="services-pricing-body-wrapper-packages">
          <ServicesPackageList title={"Paw Tracker Technology"} />
          <ServicesPackageList title={"Farewell Room (30mins)"} />
          <ServicesPackageList title={"Post Mortem Grooming"} />
          <ServicesPackageList title={"Asssited Memorial Service"} />
          <ServicesPackageList title={"Interactive Farewell Process"} />
          <ServicesPackageList title={"Private Cremation"} />
          <ServicesPackageList title={"Certification of Cremation"} />
          <ServicesPackageList title={"Printed Ceramic Urn"} />
          <ServicesPackageList title={"Velvet Ash Pouch"} />
          <ServicesPackageList title={"Ash Necklace"} />
          <ServicesPackageList title={"Fur & Nail Bottles"} />
          <ServicesPackageList title={"Aeternal Box of Memorabilia"} />
          <ServicesPackageList title={"Memorial Photo"} />
          <ServicesPackageList title={"Clay Paw Print"} />
          <ServicesPackageList title={"Personal Letter"} />
          <ServicesPackageList title={"Dried Flowers"} />
          <ServicesPackageList title={"Paw Print"} />
          <ServicesPackageList title={"A4 Printed Photo"} />
          <ServicesPackageList title={"Hair Glass Bottle"} />
        </div>

        <div className="services-pricing-body-wrapper-description">
          {/* <span className="services-pricing-body-footer-span">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </span> */}

          <a href="tel:+639952610478">
            <div className="services-pricing-body-button-wrapper">
              <button title="Speak to Us">INQUIRE NOW</button>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}
